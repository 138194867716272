import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import Receive from '@m/cabinet/components/views/Receive.vue'
import { getBranchResource } from '@/services/facility.service'
import { FACILITY_BRANCHES_VIEW_NAME, FACILITY_GENERAL_VIEW_NAME } from '@m/cabinet/modules/Facility/router'

import {
  GENERAL_TAB_KEY,
  SERVICES_TAB_KEY,
  DOCTORS_TAB_KEY,
  MEDIA_TAB_KEY,
  SCHEDULE_TAB_KEY,
} from '@/modules/cabinet/modules/Branch/const'

const BRANCH_PREFIX = 'cabinet:branch'
const p = (name: string) => BRANCH_PREFIX + ':' + name
const s = (name: string) => name + ':' + 'single'

export const BRANCH_VIEW = p('view')
export const BRANCH_CREATE_VIEW = p('create')
export const BRANCH_EDIT_VIEW = p(GENERAL_TAB_KEY)
export const BRANCH_SERVICES_VIEW = p(SERVICES_TAB_KEY)
export const BRANCH_DOCTORS_VIEW = p(DOCTORS_TAB_KEY)
export const BRANCH_MEDIA_VIEW = p(MEDIA_TAB_KEY)
export const BRANCH_SCHEDULE_VIEW = p(SCHEDULE_TAB_KEY)
export const BRANCH_EDIT_SINGLE_VIEW = s(BRANCH_EDIT_VIEW)
export const BRANCH_SERVICES_SINGLE_VIEW = s(BRANCH_SERVICES_VIEW)
export const BRANCH_DOCTORS_SINGLE_VIEW = s(BRANCH_DOCTORS_VIEW)
export const BRANCH_MEDIA_SINGLE_VIEW = s(BRANCH_MEDIA_VIEW)
export const BRANCH_SCHEDULE_SINGLE_VIEW = s(BRANCH_SCHEDULE_VIEW)

const View = () => import('./views/View.vue')
const Create = () => import('./views/Create.vue')

const Services = () => import('@m/cabinet/modules/Branch/tabs/Services.vue')
const Doctors = () => import('@m/cabinet/modules/Branch/tabs/Doctors.vue')
const Media = () => import('@m/cabinet/modules/Branch/tabs/Media.vue')
const Schedule = () => import('@m/cabinet/modules/Branch/tabs/Schedule.vue')

const BASE_MODEL_NAME = 'branch/'

const FACILITY_BACK_PARAMS = [{ key: 'id', from: 'facility', path: ['id'] }]
const FALLBACK_PARAMS = {
  fallbackRouteName: FACILITY_BRANCHES_VIEW_NAME,
  parameters: FACILITY_BACK_PARAMS,
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global

  const getBranchRoutes = underFacility => {
    return [

      route('general/', Create, underFacility ? BRANCH_EDIT_VIEW : BRANCH_EDIT_SINGLE_VIEW, {
        meta: {
          title: () => $t('Edit branch'),
          ...(underFacility ? FALLBACK_PARAMS : {})
        }
      }),
      route('services/', Services, underFacility ? BRANCH_SERVICES_VIEW : BRANCH_SERVICES_SINGLE_VIEW, {
        meta: {
          title: () => $t('Services'),
          ...(underFacility ? FALLBACK_PARAMS : {})
        },
      }),
      route('doctors/', Doctors, underFacility ? BRANCH_DOCTORS_VIEW : BRANCH_DOCTORS_SINGLE_VIEW, {
        meta: {
          title: () => $t('Doctors'),
          ...(underFacility ? FALLBACK_PARAMS : {})
        },
      }),
      route('media/', Media, underFacility ? BRANCH_MEDIA_VIEW : BRANCH_MEDIA_SINGLE_VIEW, {
        meta: {
          title: () => $t('Media'),
          ...(underFacility ? FALLBACK_PARAMS : {})
        },
      }),
      route('schedule/', Schedule, underFacility ? BRANCH_SCHEDULE_VIEW : BRANCH_SCHEDULE_SINGLE_VIEW, {
        meta: {
          title: () => $t('Schedule'),
          ...(underFacility ? FALLBACK_PARAMS : {})
        },
      }),
    ]
  }
  return [
    route(':id/create/', Create, BRANCH_CREATE_VIEW, {
      meta: {
        title: () =>  $t('Create branch'),
        fallbackRouteName: FACILITY_GENERAL_VIEW_NAME,
        parameters: FACILITY_BACK_PARAMS,
      }
    }),
    receiveRoute(
      getBranchResource,
      [
        prefixRoutes(':id/', [
          ...getBranchRoutes(true)
          
        ], {meta: {}, component: View, props: { resource: getBranchResource, resourceKey: 'id', routeKey: 'branch' } },),
      ],
      Receive,
      '',
      'branch',
      'id'
    ),
    // prefixRoutes(
    // ':id/:branch/', [
    //   ...getBranchRoutes(true)
      
    // ],
    // {
    //     meta: {}, 
    //     component: View, 
    //     props: { 
    //       resource: getBranchResource, 
    //       resourceKey: 'id', 
    //       routeKey: 'branch' 
    //     } 
    //   },
    // ),
    receiveRoute(
      getBranchResource,
      [
        prefixRoutes('', [
          ...getBranchRoutes(false)
          
        ],
        {
          meta: {}, 
          component: View,
          props: { 
            resource: getBranchResource,
            resourceKey: 'id',
            routeKey: 'branch'
          },
        },
      ),
        // route(
        //   ':branch/single/', 
        //   View,
        //   BRANCH_VIEW,
        //   {
        //     meta: {}, 
        //     children: [
        //       ...getBranchRoutes(false)
          
        //     ],
        //   },
        // ),
        // { path: '', redirect: { name: BRANCH_EDIT_SINGLE_VIEW, params:  } },      
      ],
      Receive,
      '',
      'branch',
      'id'
    ),
    // route(':branch/', View, BRANCH_VIEW, {
    //   children: [
        // receiveRoute(
    //   getBranchResource,
    //   [
    //   ],
    //   Receive,
    //   '',
    //   'branch',
    //   'id',
    // ),
        // route('general/', Edit, p(GENERAL_TAB_KEY), {
        //   meta: {
        //     title: () => $t('Edit branch'),
        //   }
        // }),
        
      // ],
    // },),        
    // receiveRoute(
    //   getBranchResource,
    //   [
    //   ],
    //   Receive,
    //   '',
    //   'branch',
    //   'id',
    // ),
    // route(':branch/', View, BRANCH_VIEW, {
    //   meta: {
    //     title: () => $t('Branch profile'),
    //   },
    //   children: [
    //     route('services/', Services, p(SERVICES_TAB_KEY), {
    //       meta: {
    //         title: () => $t('Services'),
    //       },
    //     }),
    //     route('doctors/', Doctors, p(DOCTORS_TAB_KEY), {
    //       meta: {
    //         title: () => $t('Doctors'),
    //       },
    //     }),
    //     route('media/', Media, p(MEDIA_TAB_KEY), {
    //       meta: {
    //         title: () => $t('Media'),
    //       },
    //     }),
    //     route('schedule/', Schedule, p(SCHEDULE_TAB_KEY), {
    //       meta: {
    //         title: () => $t('Schedule'),
    //       },
    //     }),
    //   ],
    // }),
    
  ]
}

export { BASE_MODEL_NAME }