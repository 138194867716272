import { MEDIA_TAB_KEY, SCHEDULE_TAB_KEY } from '@m/cabinet/modules/Branch/const'
import { i18n } from '@/i18n'

const { t: $t } = i18n.global

export const GENERAL_TAB_KEY: string = 'general'
export const SERVICES_TAB_KEY: string = 'services'
export const DOCUMENTS_TAB_KEY: string = 'documents'
export const BRANCHES_TAB_KEY: string = 'branches'
export const DOCTORS_TAB_KEY: string = 'doctors'
export const DOCUMENTS_FLOW: string = 'documents-flow'

export const MULTIPLE_BRANCH_FACILITY_TABS = [
  GENERAL_TAB_KEY,
  DOCUMENTS_TAB_KEY,
  SERVICES_TAB_KEY,
  DOCTORS_TAB_KEY,
  BRANCHES_TAB_KEY,
]
export const SINGLE_BRANCH_FACILITY_TABS = [
  GENERAL_TAB_KEY,
  DOCUMENTS_TAB_KEY,
  MEDIA_TAB_KEY,
  SCHEDULE_TAB_KEY,
  SERVICES_TAB_KEY,
  DOCTORS_TAB_KEY,
]

export enum ServiceStatuses {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}
export const SERVICES_STATUSES = [
  {
    value: ServiceStatuses.DRAFT,
    label: $t('Draft'),
  },
  {
    value: ServiceStatuses.ACTIVE,
    label: $t('Active'),
  },
  {
    value: ServiceStatuses.ARCHIVE,
    label: $t('Archive'),
  },
]

export enum BranchJoinStatuses  {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  STOPPED = 'stopped',
}
export enum BranchPublicationStatus  {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export enum StatusModerationChoices {
  IN_PROCESS = 'in_process',
  WAITING_MODERATION_START = 'waiting_moderation_start',
  BEING_CHECKED = 'being_checked',
  WAITING_SIGNED_CONTRACT = 'waiting_signed_contract',
  ACTIVATED = 'activated',
  NOT_SUCCESSFUL = 'not_successful',
  BLOCKED = 'blocked'
}

export const GET_MODERATION_STATUSES = t => ([
  {
    value: StatusModerationChoices.IN_PROCESS,
    label: t('In process of filling out information'),
    icon: 'i-status-process',
    color: '#B81ED1',
  },
  {
    value: StatusModerationChoices.WAITING_MODERATION_START,
    label: t('The medical facility is waiting for moderation to begin'),
    icon: 'i-status-waiting-moderation',
    color: '#008CFF'
  },
  {
    value: StatusModerationChoices.BEING_CHECKED,
    label: t('The medical facility is being checked'),
    icon: 'i-status-check',
    color: '#FFA31A',
  },
  {
    value: StatusModerationChoices.WAITING_SIGNED_CONTRACT,
    label: t('Waiting for the contract to be signed'),
    icon: 'i-status-waiting-sign',
    color: '#008CFF',
  },
  {
    value: StatusModerationChoices.ACTIVATED,
    label: t('Medical facility activated'),
    icon: 'i-status-active',
    color: '#1ED1AC',
  },
  {
    value: StatusModerationChoices.NOT_SUCCESSFUL,
    label: t('Moderation was not successful'),
    icon: 'i-status-failed',
    color: '#E74C3C',
  },
  {
    value: StatusModerationChoices.BLOCKED,
    label: t('Account is blocked'),
    icon: 'i-status-blocked',
    color: '#E74C3C',
  },
])

export const ckeditorConfig = {
  // 'undo', 'redo', '|',
  toolbar: ['heading', '|', 'bold', 'italic', '|', 'link'],
}