import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import type { Options } from '@/router'
import Receive from '@m/cabinet/components/views/Receive.vue'
import { i18n } from '@/i18n'
import { appointmentDetailsResource } from '@/services/appointment.service'

const APPOINTMENT_PREFIX = 'cabinet:appointment'
const p = (name: string) => APPOINTMENT_PREFIX + ':' + name

export const APPOINTMENT_LIST_VIEW_NAME = p('list')
export const APPOINTMENT_VIEW_NAME = p('view')

const List = () => import('./views/List.vue')
const View = () => import('./views/View.vue')

const BASE_MODEL_NAME = 'appointment/'

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global

  return [
    route('list/', List, APPOINTMENT_LIST_VIEW_NAME, {
      meta: {
        title: () => $t('Appointments'),
      },
    }),
    receiveRoute(
      appointmentDetailsResource,
      [
        route('view/', View, APPOINTMENT_VIEW_NAME, {
          meta: {
            title: (obj) => {
              console.log('object', obj)
              return $t('Appointment')
            },
            fallbackRouteName: APPOINTMENT_LIST_VIEW_NAME,
          },
        }),
        { path: '', redirect: { name: APPOINTMENT_VIEW_NAME } },
      ],
      Receive,
      ''
    ),
    
    {
      path: '',
      redirect: { name: APPOINTMENT_LIST_VIEW_NAME },
    },
  ]
}

export { BASE_MODEL_NAME }
